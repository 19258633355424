<template>
	<div  v-loading="loading">
		<div class="contents">
			<div class="top">
				<div class="userinfo">
					<div class="title-b">账户信息</div>
					<div class="cmp-wrap">
						<div class="info-wrap">
							<div class="avatar-wrap">
								<div class="avatar-avatarinfo">
									<div class="avatar">
										<img id="userinfo-image" v-if="userInfo.avatar" :src="url+userInfo.avatar" alt="荣朴科技公司">
									</div>
									<div class="base-info">
										<p>Hi</p>
										<p class="black company" id="userinfo-name">{{userInfo.name}}</p>
										<p class="adm" id="userinfo-phone">{{userInfo.phone}}</p>
									</div>
								</div>
								<div class="block recharge">
									<div class="block-des">当前余额
										<span class="unit">（ 元 ）</span>
									</div>
									<div class="title-wrap">
										<div class="block-title black" id="userinfo-money">{{userInfo.balance}}</div>
									</div>
									<div class="block-des">
										<Button type="primary" @click="withdrawal(userInfo.balance)">提现</Button>
										<!-- <Button style="margin-left: 10px;" @click="withdrawals" type="primary">提现</Button> -->
									</div>
	
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="userinfo">
					<div class="title-b">联系方式</div>
					<div class="cmp-wrap">
						<div class="contact">
							<div class="wrap left">
								<p class="zsdj">产品咨询</p>
								<p class="name">匿名</p>
								<p class="phone">手机号: <span id="phone1">13071066017</span></p>
								<p class="phone">紧急联系人:  <span id="phone2">17638592266</span></p>
								<a target="_blank" title="qq交谈" id="cp_qq" href="http://wpa.qq.com/msgrd?v=3&amp;uin=3133307466&amp;site=qq&amp;amp;menu=yes">
									<Button>QQ交谈</Button>
								</a>
							</div>
							<div class="wrap">
								<p class="zsdj">技术对接</p>
								<p class="name">匿名</p>
								<p class="phone">手机号: <span id="phone3">13071066017</span></p>
								<p class="phone">紧急联系人: <span id="phone4">17638592266</span></p>
								<a target="_blank" title="qq交谈" id="js_qq" href="http://wpa.qq.com/msgrd?v=3&amp;uin=3133307466&amp;site=qq&amp;amp;menu=yes">
									<Button>QQ交谈</Button>
								</a>
							</div>
						</div>
					</div>
				</div>
			</div>
				
				
				
		</div>
		<el-row :gutter="20">
			<el-col :span="22">
				<div class="grid-content bg-purple-light">
					<div class="search">
						<el-form :inline="true"  ref="formInline" :model="formInline" class="demo-form-inline">
							<el-form-item label="服务商" prop="service">
								<el-input v-model="formInline.service" placeholder="服务商"></el-input>
							</el-form-item>
							<el-form-item label="类型" prop="status">
								<el-select v-model="formInline.status" placeholder="类型">
									<el-option label="费率增加" value="1"></el-option>
									<el-option label="提现锁定" value="2"></el-option>
									<el-option label="提现扣除" value="3"></el-option>
									<el-option label="提现驳回" value="4"></el-option>
									<el-option label="提现退返" value="5"></el-option>
								</el-select>
							</el-form-item>
							
							<el-form-item>
								<el-button type="primary" @click="request()">查询</el-button>
								<el-button @click="reset('formInline')">重置</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-table ref="listTable" v-loading="loading" :header-cell-style="{ background: '#F5F7FA', color: '#606266' }" :data="data" lazy style="width: 100%;"  border="" row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
			<el-table-column align="center" prop="id" label="编号" sortable width="120"></el-table-column>
			<el-table-column align="center" label="服务商名称">
				<template slot-scope="scope" >
					{{scope.row.service.name}}
				</template>
			</el-table-column>
			<el-table-column align="center" label="类型"  width="120">
				<template slot-scope="scope" >
					<!-- <el-tag v-if="scope.row.status==1" type="info">审核中</el-tag> -->
					<el-tag v-if="scope.row.status==1">费率增加</el-tag>
					<el-tag v-else-if="scope.row.status==2||scope.row.status==6||scope.row.status==7" type="warning">提现锁定</el-tag>
					<el-tag v-else-if="scope.row.status==3" type="success">提现扣除</el-tag>
					<el-tag v-else-if="scope.row.status==4" type="danger">提现驳回</el-tag>
					<el-tag v-else-if="scope.row.status==5" type="info">提现退返</el-tag>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="qmoney" label="变动前余额" sortable></el-table-column>
			<el-table-column align="center" prop="money" label="变动余额" sortable ></el-table-column>
			<el-table-column align="center" prop="hmoney" label="变动后余额" sortable ></el-table-column>
			<el-table-column align="center" prop="created_at" label="变动时间" sortable ></el-table-column>
		</el-table>
		<el-pagination @prev-click="prev" @next-click="next" @current-change="CurrentChange" layout="total,prev, pager, next" :page-size="pageSize" :total="total"></el-pagination>
		<Modal v-model="router"  @on-cancel="ModalCancel" :mask="false" title="提现">
		       <router-view  @routerCancel="ModalCancel"></router-view>
		</Modal>
		
	</div>
	
</template>

<script >
	import {request} from "@/library/request.js";
	export default {
		data () {
			return {
				loading:false,
				router:false,
				userInfo:[],
				url:this.common.url,
				data:[],
				total:0,
				pageSize:15,
				currentPage:0,
				formInline:{
					service:"",
					status:"",
				},
			}
		},
		methods: {
			request(){
				var search = {
					limit:this.pageSize,
					page:this.currentPage,
					service:this.formInline.service,
					status:this.formInline.status,
				}
				this.loading = true
				this.$Loading.start();
				this.spinShow=true
				return request({
					url:'finance/detail',
					method:"get",
					params:search
				}).then(response  => {
					this.loading = false
					this.$Loading.finish();
					this.userInfo = response.data.detail,
					this.data = response.data.balance.data
				}).then(err => {
					if(err){
						this.$Loading.error();
						this.loading = false
						this.$Message.error("系统异常，请稍后重试")
					}
				})
			},
			withdrawal(balance){
				this.router=true
				this.$router.push({
					name:'finance/withdrawal',
					params:{balance:balance}
				})
			},
			ModalCancel(res){
				
				this.router=false
				this.$router.push({
					path:'/finance/list'
				})
				if(res){
					this.request()
				}
				
			},
			prev(value){
				this.currentPage = value
				this.request()
			},
			next(value){
				this.currentPage = value
				this.request()
			},
			CurrentChange(value){
				this.currentPage = value
				this.request()
			},
			reset(formName){
				// console.log(this.$refs[formName])
				this.$refs[formName].resetFields();
				this.request()
			},
		},
		mounted() {
			var route =this.$route.path;
			if(route!="/finance/list"){
				this.ModalCancel()
			}
			this.request()
		},
		
	}
</script>

<style  scoped  >
	@import url('/css/app.css');
	.body {
		background-color: rgb(255 255 255);
	}
	.contents{
		margin: 20px;
		height: 210px;
	}
	.top {
		display: flex;
	}
	.top .userinfo {
		background-color: #fff;
		width: 50%;
		height: 100%;
		line-height: 39px;
		padding: 19px;
		margin-right: 35px;
		margin-bottom: 20px;
	}
	.top .userinfo .title-b {
		margin-bottom: 20px;
		padding-left: 13px;
		height: 14px;
		line-height: 14px;
		font-size: 14px;
		border-left: 4px solid #01bfcb;
		color: #878a9d;
		font-weight: 700;
	}
	.info-wrap {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		flex-wrap: nowrap;
	}
	.info-wrap .avatar-wrap {
		display: contents;
		flex-direction: column;
		width: 264px;
	}
	.info-wrap .avatar-wrap .avatar-avatarinfo {
		flex-direction: row;
		display: flex;
		padding-bottom: 14px;
	}
	.info-wrap .avatar-wrap .avatar {
		margin-right: 24px;
		width: 82px;
		height: 82px;
		border: 1px solid #efefef;
		border-radius: 50%;
	}
	.info-wrap .avatar-wrap .avatar img {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}
	.info-wrap .recharge {
		border-left: 1px solid #efefef;
		width: 50%;
	}
	.info-wrap .block {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.info-wrap .block .block-des .unit {
		color: #649eff;
	}
	.info-wrap .block .block-title {
		height: 34px;
		line-height: 34px;
		font-size: 30px;
		margin: 15px 0;
	}

	.black {
		color: #59607c;
	}
	.contact .wrap, .contact {
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.contact .wrap {
		width: 50%;
		flex-direction: column;
	}
	.contact .wrap .zsdj {
		color: #84899f;
		font-size: 12px;
		line-height: 12px;
		margin-bottom: 15px;
	}
	.contact .wrap .name {
		color: #59607c;
		font-size: 14px;
		line-height: 12px;
		margin-bottom: 16px;
	}
	.contact .wrap .phone {
		color: #59607c;
		font-size: 12px;
		line-height: 11px;
		margin-bottom: 20px;
	}
	.contact .wrap {
		width: 50%;
		flex-direction: column;
	}
	.order {
		padding: 15px;
		background: #fff;
		margin-right: 35px;
		margin-left: 8px;
		margin-bottom: 20px;
	}
	.title {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
	}
	.order .title {
		margin-bottom: 20px;
		padding-left: 13px;
		height: 14px;
		line-height: 14px;
		font-size: 14px;
		border-left: 4px solid #01bfcb;
		color: #878a9d;
		font-weight: 700;
	}
	.order section {
		display: flex;
	}
	.order section>div {
		flex: 1;
		border: 1px solid #E4EAED;
		padding: 30px;
		background: #FBFDFF;
	}
	.order section p {
		font-size: 20px;
		margin-bottom: 30px;
	}
	.order section i {
		font-style: normal;
		width: 110px;
	}
	.order section>div span {
		color: #000;
		font-weight: bold;
	}
	.order section>div div {
		display: flex;
		line-height: 24px;
		color: #666666;
		margin-bottom: 20px;
	}
</style>
